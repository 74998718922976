import "vidstack/player/styles/default/captions.css";
import "vidstack/player/styles/default/controls.css";
import "vidstack/player/styles/default/time.css";
import "vidstack/player/styles/default/sliders.css";
import "vidstack/player/styles/default/buttons.css";
import {
defineCustomElement,
MediaCaptionsElement,
MediaControlsElement,
MediaControlsGroupElement,
MediaTimeSliderElement,
MediaPlayButtonElement,
MediaMuteButtonElement,
MediaVolumeSliderElement,
MediaTimeElement,
MediaSeekButtonElement,
MediaCaptionButtonElement
} from "vidstack/elements";

defineCustomElement(MediaCaptionsElement);
defineCustomElement(MediaControlsElement);
defineCustomElement(MediaControlsGroupElement);
defineCustomElement(MediaTimeSliderElement);
defineCustomElement(MediaPlayButtonElement);
defineCustomElement(MediaMuteButtonElement);
defineCustomElement(MediaVolumeSliderElement);
defineCustomElement(MediaTimeElement);
defineCustomElement(MediaSeekButtonElement);
defineCustomElement(MediaCaptionButtonElement);