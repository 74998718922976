<script setup lang="ts">
import type { TrainingParticipant } from "~/server/routes/admin/v1/training/[type]/[id]/participation"
import type { PaginationResponse } from "~ui/types/paginate_meta"
import type { TrainingMaterial } from "~/server/routes/admin/v1/training"

definePageMeta({
  layout: false,
})

const { t, locale } = useI18n()
useHead({ title: computed(() => t("seo_titles.training.participation")) })

const filter = ref<{ status: "all" | "active" | "inactive"; locale: string }>({
  status: "active",
  locale: locale.value,
})

const route = useRoute("elearning-type-id")
const trainingId = route.params.id
const type = route.params.type.toString()

const typeTranslation = computed(() => {
  return type == "course" ? t("training.type.course") : t("training.type.video")
})

const hiddenClass = "hidden @xl/main:table-cell"

const { data, error, status, sortColumn } = await useTableFetch<
  PaginationResponse<TrainingParticipant>
>(`/admin/v1/training/${type}/${trainingId}/participation`, "post", filter)

const { data: trainingMaterial } = useCachedFetch<TrainingMaterial>(
  `/admin/v1/training/${type}/${trainingId}`,
  { method: "post", body: { locale: locale.value } },
)

const { mutate: downloadCsv, loading: csvExportLoading } = useMutation<{
  url: string
}>({
  url: `/admin/v1/training/${type}/${trainingId}/export`,
  onSuccess: (result) => {
    window.location.href = result.url
  },
})

const headers = [
  { title: t("tables.employee"), key: "first_name", sortable: false },
  {
    title: t("tables.status"),
    key: "status",
    sortable: true,
    class: hiddenClass,
  },
  {
    title: t("tables.lesson_status"),
    key: "completed",
    sortable: true,
  },
  {
    title: t("tables.invited"),
    key: "invited",
    sortable: false,
    class: hiddenClass,
  },
]

const participants = computed(() => data.value?.data)
const videoOpened = ref(false)
</script>
<template>
  <NuxtLayout name="two-columns">
    <DialogWrapper v-model:open="videoOpened">
      <dialog
        class="relative max-h-[95svh] w-full max-w-2xl flex-col overflow-auto rounded-t-lg bg-white text-left shadow-xl transition-all sm:top-8 sm:mx-auto sm:rounded-lg"
        :open="videoOpened"
      >
        <VideoPlayer
          v-if="trainingMaterial?.type == 'video'"
          allow-fast-forward
          :autoplay="false"
          :pending="false"
          default-caption-locale="en_US"
          :error-text="$t('videos.error')"
          :src="trainingMaterial.external_id"
        />
      </dialog>
    </DialogWrapper>
    <PageHeader
      :breadcrumb-items="[
        {
          name: $t('titles.training.title'),
          link: '/elearning',
        },
        { name: $t('titles.training.participation.title') },
      ]"
    >
      <template #heading>
        {{ $t("titles.training.participation.title") }}
      </template>
      <ExportCsvButton
        class="ml-auto"
        :loading="csvExportLoading"
        @click="downloadCsv"
      />
    </PageHeader>
    <TableFilterContainer>
      <TableFilter
        v-model="filter.status"
        :label="$t('tables.filters.training_type')"
        :options="[
          { value: 'all', text: $t('forms.filters.all') },
          { value: 'active', text: $t('forms.filters.only_active') },
          { value: 'inactive', text: $t('forms.filters.only_inactive') },
        ]"
      />
    </TableFilterContainer>
    <Table
      :meta="data?.meta"
      :status
      :has-data="Boolean(participants)"
      :empty="participants?.length == 0"
      :error
    >
      <template #empty>
        <EmptyState>
          <template #heading>
            {{ $t("empty.employees_training.title") }}
          </template>
          <template #description>
            {{
              $t("empty.employees_training.description", {
                type: typeTranslation,
              })
            }}
          </template>
        </EmptyState>
      </template>

      <template #head>
        <TableHeader v-model="sortColumn" :headers />
      </template>

      <TableRow
        v-for="participant in participants"
        :key="participant.id"
        class="cursor-pointer"
        :to="`/team/employees/${participant.id}`"
      >
        <TableCell>
          <div class="flex items-center gap-4">
            <Avatar :image="participant.avatar ?? undefined" />
            <div class="flex flex-col">
              <span class="font-medium">
                {{ participant.full_name || $t("employees.anonymous_name") }}
              </span>
              <span
                class="max-w-24 truncate font-medium text-slate-500 @lg/main:max-w-32 @2xl/main:max-w-full"
              >
                {{ participant.email || $t("employees.no_email_found") }}
              </span>
            </div>
          </div>
        </TableCell>
        <TableCell :class="hiddenClass">
          <Badge
            :variant="participant.status == 'active' ? 'success' : 'error'"
            class="capitalize"
            >{{
              $t(`dynamic_keys.employees.status.${participant.status}`)
            }}</Badge
          >
        </TableCell>
        <TableCell>
          <Badge
            :variant="participant.completed ? 'success' : 'error'"
            class="capitalize"
          >
            <template v-if="participant.completed">
              {{ $t("training.completed_status.completed") }}
            </template>
            <template v-else>
              {{ $t("training.completed_status.not_completed") }}
            </template>
          </Badge>
        </TableCell>
        <TableCell :class="hiddenClass">
          <Badge
            :variant="participant.invited ? 'success' : 'error'"
            class="capitalize"
          >
            <template v-if="participant.invited">
              {{ $t("training.invited_status.invited") }}
            </template>
            <template v-else>
              {{ $t("training.invited_status.not_invited") }}
            </template>
          </Badge>
        </TableCell>
      </TableRow>

      <template #loading>
        <TableRow v-for="i in participants?.length ?? 10" :key="i">
          <TableCell class="w-1/2"
            ><div class="flex items-center gap-4">
              <Avatar />
              <div class="flex w-2/3 flex-col gap-1">
                <SkeletonSingleLine class="h-5" />
                <SkeletonSingleLine />
              </div></div
          ></TableCell>
          <SkeletonTableCell :class="hiddenClass" />
          <SkeletonTableCell />
          <SkeletonTableCell :class="hiddenClass" />
        </TableRow>
      </template>
    </Table>
    <template #right>
      <LayoutCard :gutter="false" class="overflow-hidden">
        <template v-if="trainingMaterial">
          <button class="relative min-w-full" @click="videoOpened = true">
            <img
              :src="trainingMaterial.image"
              class="aspect-video object-cover object-center"
            />
            <div
              v-if="trainingMaterial.type == 'video'"
              class="absolute inset-0 grid h-full w-full items-center justify-center"
            >
              <VideoDuration :duration="trainingMaterial.duration" />
            </div>
          </button>
          <div class="border-t p-4">
            <h3 class="text-sm font-medium text-slate-900">
              {{ trainingMaterial.title }}
            </h3>
            <span class="text-sm text-slate-800 italic">
              {{ trainingMaterial.type }}
            </span>
          </div>
        </template>
        <div v-else class="p-4">
          <div class="aspect-video" />
          <SkeletonSingleLine class="w-24" />
          <SkeletonSingleLine class="mt-2 h-3! w-24" />
        </div>
      </LayoutCard>
    </template>
  </NuxtLayout>
</template>
